

.ReportPointsDiv{
    position: relative;
    left: 30px;
    top: 30px;
    font-size: 20px;
    font-weight: 500;
}
.CountText{
    color: var(--BackGround-color-Blue-Bold);
}
.PackgeCon{
    position: relative;
    height: 478px;
}
.BuyBtn{
    position: absolute;
    bottom: 0;
    left: 25%;
    margin-bottom: 20px;
}