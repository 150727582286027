.table-header:hover
{
    background-color: rgb(230, 228, 228);
}
.table-header
{
    transition: background-color 0.5s ease;

}
.loader
{
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.64);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    top: 0;
    z-index: 10;
    left: 0;
}