.Button{
    display: inline-block;
    /* margin-left: 43px; */
    /* margin-bottom: 18px; */
    width: 100% !important;
    height: 100%;
}
.BTN{
    width: 100% !important;
    height: 100% !important;
    background: var(--main-color);
    border: 0;
    height: 27px;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}