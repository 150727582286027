.AllBtnCenterInd{
    width: 10%;
    position: relative;
    left: 23%;
    top: 200px;
}
.BtnCenterInd{
    width: 300px;
    height: 60px;
    border: 2px solid;
    border-radius: 30px;
    background: #B42F27;
    color: #ffffff;
    font-size: 24px;
}
.BtnCenterInd:hover{
background-color: #79B934;
}
.Dashboardd{
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    top: 250px;
}
.WitingMess{
    font-size: 20px;
    color: var(--BackGround-color-Blue);
    position: relative;
    top: 111px;
    left: 400px;
}

.form-group{
    margin: 18px;
}


.container {
    width: 850px;
    height: 500px;
    margin: 0 auto;
    padding: 20px;
    /* background-color: #f4f4f4; */
    border-radius: 10px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  }
  
  .profile {
    display: flex;
    align-items: center;
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  
  .profile-picture img {
    width: 100%;
    height: 100%;
  }
  
  .profile-details {
    flex: 1;
  }
  
  .profile-details h1 {
    margin: 0;
    color: #333;
  }
  
  .profile-details p {
    margin: 5px 0;
    color: #666;
  }
  
  @media (max-width: 900px) {
    .section {
      width: calc(100% - 40px);
      height: auto;
    }
  }
  
  