  @import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400&family=Caprasimo&family=Open+Sans:wght@400;600&family=Pacifico&family=Roboto+Condensed:wght@300;400;700&family=Roboto+Slab:wght@100;200;400&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400&family=Caprasimo&family=Open+Sans:wght@400;600&family=Pacifico&family=Roboto+Condensed:wght@300;400;700&family=Roboto+Slab:wght@100;200;400&family=Rubik&display=swap');
  @import url('https://fonts.cdnfonts.com/css/bebas-kai');
  @import url(https://db.onlinewebfonts.com/c/513071b47bdba774c93a73ad16a75e3b?family=DIN+Next+LT+Arabic+Bold);
    body{
  font-family: 'Readex Pro', sans-serif;
  /* font-family: Tajawal; */
}
:root{
  --main-color:rgb(13, 81, 159);
  --BackGround-color-Blue: #336ea1;
  --BackGround-color-Blue-Bold: #1b4561;
  --BackGround-color-Blue-Sco: #58b2d8;
  --BackGround-color-Red:#953834;
  --BackGround-color-Red-Sco:#de6b64;
  --BackGround-color-Gray:#585a5b;
  --BackGround-color-Gray-Sco: #989a9b;
  --BackGround-color-Green:#579e52;
  --BackGround-color-Green-Sco:#83be68;
  --Font-Color : #357a9b;
  --large-font-size:16px;;
  --font-weight: 900;
  --MinFont-Family: "Nunito", Sans-serif; ;
  --ScoFont-Family: "DIN Next LT Arabic Bold";
  --ar-Font: 'Readex Pro', sans-serif;
}
.Table{
  width: 100% !important;
  min-height: auto;
}
tbody{
  position: relative;
  top: 6px;
}
.tableheader{
  background-color: var(--BackGround-color-Blue-Bold) !important;
  color: white !important;
  margin: 8px !important;
  text-align: center;
}
.tablerowColor{
  background-color: #336ea10b !important;
}
.tablerow{
  margin: 8px !important;
  text-align: center;
  width: auto;
}
/* .TextArea{
  width: 400px !important;
  height: 100px !important;
} */
.backGroungQus{
  background-color: var(--BackGround-color-Blue-Bold) ;
}
.display{
  display: none !important;
}
.DisplayBlock{
  display: block;
}
.Displayinline{
  display: inline !important;
}
button{
  cursor: pointer;
}
.RegsterBtn{
  width: 120px;
  display: inline-block;
  margin: 12px;
}
.yallo{
  color: yellow;
}

.blue{
  color: blue;
}
.PostionForReportCom{
  position: absolute;
  top: 0;
  left: -2000px;
}
a{
  text-decoration: none !important;
}