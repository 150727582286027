.PayRightSection{
    width: 100%;
    height: 100%;
}
.PayRightSection .PaymentContaner{
    width: 100%;
    height: 100%;
}
.PayRightSection .PaymentContaner .PaymentTitl{
    width: 100%;
    height: 36px;
    font-size: 26px;
    text-align: center;
    color: var(--main-color);
}
.PayRightSection .PaymentContaner .exams{
    width: 100%;
    height: auto;
    padding: '10px';
    position: relative;
}
.PayRightSection .PaymentContaner .exams .ExamContaner{
    width: 100%;
    height: 100%;
    display: flex;
}
.PayRightSection .PaymentContaner .exams .exam{
    width: calc(100% - 15px);
    height: 100%;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1;
    display: flex;
    background: white;
}
.PayRightSection .PaymentContaner .exams .exam div img{
    height: 100%;
    width: 100%;
    border-radius: 15px;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2){
    width: 70%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .ExamTitle{
    display: inline;
    color:#00519E;
    font-weight: 600;
    font-size: 1.75rem;
    text-align: center;
    height: 20%;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .Examdescription{
    font-size: 18px;
    color: #7A7A7A;
    font-weight: 400;
    height: 75%;
    text-align: center;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon  {
    width: 100%;
    height: 15%;
    position: relative;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn {
    width: 90%;
    height: 100%;
    border: 1.5px solid #a63a2f;
    border-radius: 5px;
    display: flex;
    margin: auto;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn {

}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn span{
width: 25%;
height: 100%;
color: #B42F27;
font-size: 1.5rem;
font-weight: 700;
display: flex;
align-items: center;
justify-content: center;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn{

}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn div{
    width: 75%;
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn div button{
    width: 98%;
    height: 88%;
    border: 0;
    border-radius: 5px;
    background: #a63a2f;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn div button:hover{
    background: #39AA35;
}
.PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn div .not:hover{
    background: #a63a2f;
}
@media (max-width: 750px) {
    .PayRightSection .PaymentContaner .exams .exam div:nth-child(1){
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .PayRightSection .PaymentContaner .exams .exam div img{
        height: 56%;
        width: 95%;
        border-radius: 6px;
    }
    .PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .ExamTitle{
        font-size: 16px;
    }
    .PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .Examdescription{
        font-size: 13px;
        height: 139px;
        overflow: hidden;
    }
    .PayRightSection .PaymentContaner .exams{
        height: 266px;
    }
    .PayRightSection .PaymentContaner .exams .exam div:nth-child(2) .PriceAndBtncon .PriceAndBtn span{
        font-size: .6rem;
    }
}