
    /* START REPORT 5 */

    /* Start ManyBox-7 */
    .ManyBox-7{
        position: relative;
    } 
    .ManyBox-7 .IntroBatrn{
        position: absolute;
        left: 16%;
    }
    .ManyBox-7 .ManyBoxesBox{
        width: 835px;
        height: 326px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-7 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -81px;
    }
    .ManyBox-7 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 20px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-7 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 33px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-7 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-7 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 40px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 155px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-7 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-7 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 48px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-7 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-7 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 50px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;
    }
    .ManyBox-7 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0px;
    }
    .ManyBox-7 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-7 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Red);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        direction: rtl;
    }
    /* End ManyBox-7 */

    /* Start ManyBox-8 */
    .ManyBox-8{
        position: relative;
    }
    .ManyBox-8 .ManyBoxesBox{
        width: 835px;
        height: 330px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-8 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -84px !important;
    }
    .ManyBox-8 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 27px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-8 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 33px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-8 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-8 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 42px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 155px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-8 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-8 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 52px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-8 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-8 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 50px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;
    }
    .ManyBox-8 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0px;
    }
    .ManyBox-8 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-8 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Red);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        direction: rtl;
    }
    /* End ManyBox-8 */

    /* Start ManyBox-9 */
    .ManyBox-9{
        position: relative;
    }
    .ManyBox-9 .ManyBoxesBox{
        width: 835px;
        height: 329px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-9 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -81px;
    }
    .ManyBox-9 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 20px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-9 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 33px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-9 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-9 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 40px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 155px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-9 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-9 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 49px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-9 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-9 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 50px;
        left: 44px !important;
        display: inline-block;
        width: 89.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;
    }
    .ManyBox-9 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0px;
    }
    .ManyBox-9 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-9 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Red);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        direction: rtl;
    }
    /* End ManyBox-9 */
                    /* Start BigOneGraph-7 */
    .BigOneGraph-7{
        position: relative;
    }
    .BigOneGraph-7 .IntroBatrn{
        position: absolute;
        left: 15%;
    }
    .BigOneGraph-7 .Graph-1{
        position: absolute;
        top: 463px;
        left: 340px;
        width: 420px;
        height: 705px;
        border-radius: 12px;
    }
    .BigOneGraph-7 .TopTitle{
        position: absolute;
        top: 21%;
        width: 100%;
        height: 51px;
        display: flex;
        justify-content: center;
    } 
    .BigOneGraph-7 .TopTitle span{
        width: auto;
        height: auto;
        background-color: var(--BackGround-color-Green);
        font-size: 23px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        color: white;
        padding: 8px;
        border-radius: 12px;
    } 
    .BigOneGraph-7 .BottomTitle{
        position: absolute;
        bottom: 70px;
        width: 100%;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
    } 
    .BigOneGraph-7 .BottomTitle span{
        width: auto;
        height: auto;
        background-color: var(--BackGround-color-Green-Sco);
        font-size: 23px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        color: white;
        padding: 8px;
        border-radius: 12px;
    } 
    .BigOneGraph-7 .Graph-1 .Graph-Top-1 {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 55px; /* Total space on both sides */
    }
    
    .BigOneGraph-7 .Graph-1 .Graph-Top-1 .BigCanvas {
        width: 65px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Green);
        margin-top: auto;
        top: -1px;
    }
    .BigOneGraph-7 .Graph-1 .Graph-Bottom-1{ 
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        padding: 0 55px;
        position: relative;
        top: 0.15px;
    }
    .BigOneGraph-7 .Graph-1 .Graph-Bottom-1 .BigCanvas {
        width: 65px; /* Set the fixed width */
        position: relative;
        background-color: var(--BackGround-color-Green-Sco);
        margin-top: 1px;
    }
    .BigOneGraph-7 .Graph-1 .TopBox{
        position: absolute;
        top: -89px;
        width: 545px;
        height: 100px;
    }
    .BigOneGraph-7 .Graph-1 .TopBox div:nth-child(1){
        width: 100%;
    }
    .BigOneGraph-7 .Graph-1 .TopBox div:nth-child(1) span{
        display: inline-block;
        margin-left: 169px;
        width: 35%;
        height: 90%;
        border-radius: 16px;
        font-size: 23px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        text-align: center;
        background-color: #585a5b;
    }
    .BigOneGraph-7 .Graph-1 .TopBox div:nth-child(2){
        width: 100%;
        height: 25%;
        margin-left: 35px;
        margin-bottom: 20px;
    }
    .BigOneGraph-7 .Graph-1 .TopBox div:nth-child(3){
        width: 100%;
        height: 25%;
        margin-left: 35px;
    }
    .BigOneGraph-7 .Graph-1 .TopBox div:nth-child(2) span,
    .BigOneGraph-7 .Graph-1 .TopBox div:nth-child(3) span
    {
        display: inline-block;
        margin-left: 12px;
        width: 100px;
        height: 100%;
        border-radius: 16px;
        font-size: 26px;
        font-weight: 500;
        font-family: var(--Bold-Font-Family);
        color: var(--Font-Color);
        text-align: center;
    }
    .BigOneGraph-7 .Graph-1 .BottomBox{
        position: absolute;
        width: 545px;
        height: 100px;
    }
    .BigOneGraph-7 .Graph-1 .BottomBox div:nth-child(3){
        width: 100%;
    }
    .BigOneGraph-7 .Graph-1 .BottomBox div:nth-child(3) span{
        display: inline-block;
        margin-left: 169px;
        width: 35%;
        height: 90%;
        border-radius: 16px;
        font-size: 23px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        text-align: center;
        background-color: #585a5b;
        margin-top: 12px;
    }
    .BigOneGraph-7 .Graph-1 .BottomBox div:nth-child(1){
        width: 100%;
        height: 25%;
        margin-left: 35px;
        margin-bottom: 20px;
    }
    .BigOneGraph-7 .Graph-1 .BottomBox div:nth-child(2){
        width: 100%;
        height: 25%;
        margin-left: 35px;
    }
    .BigOneGraph-7 .Graph-1 .BottomBox div:nth-child(1) span,
    .BigOneGraph-7 .Graph-1 .BottomBox div:nth-child(2) span
    {
        display: inline-block;
        margin-left: 12px;
        width: 100px;
        height: 100%;
        border-radius: 16px;
        font-size: 26px;
        font-weight: 500;
        font-family: var(--Bold-Font-Family);
        color: var(--Font-Color);
        text-align: center;
    }

    /* End BigOneGraph-7 */
    /* Start ManyBox-10 */
    .ManyBox-10{
        position: relative;
    }
    .ManyBox-10 .IntroBatrn{
        position: absolute;
        left: 16%;
    }
    .ManyBox-10 .ManyBoxesBox{
        width: 835px;
        height: 325px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-10 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -81px;
    }
    .ManyBox-10 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 20px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-10 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 33px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-10 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-10 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 40px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 155px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-10 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-10 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 52px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-10 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-10 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 50px;
        left: 44px !important;
        display: inline-block;
        width: 89.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;
    }
    .ManyBox-10 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0px;
    }
    .ManyBox-10 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-10 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Green);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        direction: rtl;
    }
    /* End ManyBox-10 */

    /* Start ManyBox-11 */
    .ManyBox-11{
        position: relative;
    }
    .ManyBox-11 .ManyBoxesBox{
        width: 835px;
        height: 328px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-11 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -86px !important;
    }
    .ManyBox-11 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 20px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-11 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 34px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-11 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ManyBox-11 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 45px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 155px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-11 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-11 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 52px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-11 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-11 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 50px;
        left: 19px !important;
        display: inline-block;
        width: 103.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;    
    }
    .ManyBox-11 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0;
    }
    .ManyBox-11 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-11 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Green);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        direction: rtl;
    }
    /* End ManyBox-11 */

    /* Start ManyBox-12 */
    .ManyBox-12{
        position: relative;
    }
    .ManyBox-12 .ManyBoxesBox{
        width: 835px;
        height: 328px;
        border-radius: 20px;
        font-family: var(--Regular-Font-Family);
    }
    .ManyBox-12 .ManyBoxesBox .LiftBox{
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -85px;
    }
    .ManyBox-12 .ManyBoxesBox .LiftBox .TopTitle{
        position: relative;
        top: 20px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: white;
    }
    .ManyBox-12 .ManyBoxesBox .LiftBox .TopTitleBoxs{
        position: relative;
        top: 34px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-12 .ManyBoxesBox .LiftBox .TopTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-12 .ManyBoxesBox .LiftBox .CenterBox{
        position: relative;
        top: 43px;
        left: 14px;
        margin: auto;
        width: 87%;
        height: 155px;
        border-radius: 20px;
        display: flex;
        justify-content: space-evenly;
    }
    .ManyBox-12 .ManyBoxesBox .LiftBox .CenterBox div{ 
        width: 80px;
        height: 50px;
        border: 1px solid white;
        padding: 3px;
        font-size: 14px;
        padding: 8px;
        padding-top: 10px;
        text-align: center;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
        border: transparent;
    }

    .ManyBox-12 .ManyBoxesBox .LiftBox .BottumTitleBoxs{
        position: relative;
        top: 52px;
        left: 11%;
        width: 86%;
        height: 30px;
        display: flex;
        justify-content: space-evenly;
        text-align: center;
    }
    .ManyBox-12 .ManyBoxesBox .LiftBox .BottumTitleBoxs div{
        width: 50px;
        height: 20px;
        background-color: transparent;
        border: 1px solid white;
        border-radius: 4px;
        text-align: center;
        padding: 1px;
        font-size: 8px;
        font-weight: 900;
        font-family: var(--Bold-Font-Family);
        color: white;
    }
    .ManyBox-12 .ManyBoxesBox .LiftBox .BottumTitle{
        position: relative;
        top: 50px;
        left: 44px !important;
        display: inline-block;
        width: 86.5%;
        height: 25px;
        text-align: center;
        font-family: var(--Bold-Font-Family);
        font-weight: 900;
        color: white;
        font-size: 14px;
    }
    .ManyBox-12 .ManyBoxesBox .RightBox {
        width: 50%;
        height: 100%;
        display: inline-block;
        position: relative;
        top: -0px;
    }
    .ManyBox-12 .ManyBoxesBox .RightBox .BOX{
        position: relative;
        top: 52px;
        left: -5px;
        margin: auto;
        width: 79%;
        height: 240px;
        border-radius: 20px;
    }
    .ManyBox-12 .ManyBoxesBox .RightBox .BOX span{
        color: var(--BackGround-color-Green);
        position: absolute;
        right: 5px;
        top: 5px;
        width: 97%;
        height: 100%;
        text-align: right;
        font-size: 10px;
        font-weight: 600;
        font-family: var(--Bold-Font-Family);
        overflow-wrap: break-word;
        direction: rtl;
    }
    /* End ManyBox-12 */
    
    /* Start FinlePage */
    .FinlePage{
        position: relative;
    }
    .FinlePage .FinlePageLogo{
        position: absolute;
        top: 37%;
        left: 13%;
        width: 800px;
        height: auto;
    }
    /* End FinlePage */
    /* END REPORT 5 */
