.header{

}
.LogoHeader{
    width: 200px;
}
.UlHeader{
    display: flex;
    position: absolute;
    top: 0;
    right: 160px;
}
.LiHeader{
    color: #B42F27;
    list-style: none;
    padding: 37px;
    cursor: pointer;
    font-size: 18px;
}
.LiHeader:hover{
    color: #39AA35;
}