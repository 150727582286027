.PaymentPage{
    width: 100%;
    height: 930px;
}

.PaymentPage .PayLeftSection{
    width: 25%;
    height: 740px;
    background-color: var(--main-color);
    float: left;
    height: 100%;
    position: fixed;
}

.PaymentPage .FinlDetils{
    position: relative;
    top: 5%;
    left: 9%;
    font-size: 21px;
    color: white;
}
.PaymentPage .H1Pay{
    margin: 20px;
    font-size: 20px;
    color: white;
}
.PaymentPage .FinlDet{
    /* margin: 10px; */
}
.PaymentPage .FinlePay{
    position: relative;
    top: 5%;
    font-size: 31px;
}
.PaymentPage .TotalTxt{
    margin: 28px;
    color: white;
    font-size: 22px;
}
.PaymentPage .TotalNum{
    margin: 34px;
    margin-top: 10px;
    color: white;
    font-size: 48px;
}
.PaymentPage .CodeCount{
    width: 100%;
    margin-top: 40px;
    height: 250px;
    display: flex;
}
.PaymentPage .CodeCount div{
    width: 150px;
    height: 130px;
    margin: 10px;
    margin-top: 80px;
    border: 1px solid var(--main-color);
    border-radius: 8px;
    position: relative;
}
.PaymentPage .CodeCount div span{
    font-size: 25px;
    font-weight: 500;
    position: absolute;
    top: 25%;
    left: 30%;
}
.CodeCount div:nth-child(1){

}
.CodeCount div:nth-child(2){

}
.CodeCount div:nth-child(3){

}
.PaymentPage .PayDetils{
    border-bottom: 1px solid gray;
}
.PaymentPage .TilteTest{
    margin: 0 0 7px 32px;
    display: flex;
    width: 85%;
    height: 60px;
}
.PaymentPage .Box{
    width: calc(100% / 1);
    height: 100%;
    /* margin: 4px; */
    padding: 2px;
    text-align: center;
    border: 1px solid white;
    background-color: var(--main-color);
    color: white;
    border-radius: 4px;
}
.PaymentPage .BorderBox{
    border-color: var(--main-color);
}
.PaymentPage .Text{
    position: relative;
    top: 25%;
    font-size: 16px;
    font-weight: 700;
}
.PaymentPage .Log{
    width: 200px;
    margin: auto;
}
.PaymentPage .Detils{
display: flex;
}
/* .BillingInfo{

} */
.PaymentPage .H1Payment{
    font-size: 25px;
    margin: 16px;
    border-bottom: 1px solid gray;
    padding: 8px;
}
/* .BillingInputs{

} */
.PaymentPage .LablePay{
    margin-left: 11px;
    font-size: 18px;
    font-weight: 500;
}
.PaymentPage .PaymentInput{
    width: 65%;
    height: 32px;
    margin: 8px;
}
/* .CreadtInfo{

}
.CreadtInputs{

} */
.PaymentPage .PayBtn{
    position: absolute;
    bottom: 10% ;
    left: 55%;
    width: 120px;
    height: 80px;
    font-size: 20px;
    font-weight: 500;
}