
.Individualda {
    background-color:  rgb(213 238 248 / 36%);
    height: 100vh;
    width: 100%;
    display: flex;
}

.Individualda .A{
    width: 238px;
    height: 100%;
    position: fixed;
}

.Individualda .Asidebar {
    background-color: white;
    width: 100%;
    height: 100%;
}

.Individualda .B{
    width: calc(100% - 270px);
    margin: 0 15px 0 15px;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 7% 15.9% 70.8%;
    align-content: space-between;
    position: absolute;
    right: 0;
}
.Individualda .expect{
    width: calc(100% - 270px);
    margin: 0 15px 0 15px;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 7% 82.8%;
    align-content: space-around;
    position: absolute;
    right: 0;
}

.Individualda .TopLine {
    background-color: white;
    width: 100%;
    height: 100%;
}

.Individualda .InfroBoxes {
    width: 100%;
    height: 100%;
}
.Individualda .ContentBoxes {
    background-color: white;
    width: 100%;
    height: 100%;
}

 .Toggle{
    left: 0;
}
.DashpoardLogoContaner{
    width: 100%;
    height: 20%;

}
.DashpoardContaner{
    width: 63%;
    height: 100%;
    margin: auto;
    position: relative;
    top: -15px;
}
@media (max-width: 750px) {
    .Individualda .expect{
        width: 90%;
        margin: 0 15px 0 15px;
        height: 86%;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 7% 82.8%;
        align-content: space-around;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
    }
}
