.Pre{
    width: 70%;
    height: auto;
    position: absolute;
    top: 10%;
    left: 15%;
    background-color: white;
    color: black;
    text-align: center;
    border-radius: 8px;
}

.Graph{
    width: 100%;
    /* display: flex; */
}
.ToAddPageBtn{
    width: 120px;
}


.inviteAdmin{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: white;
}
.InvteAdminCon{
    width: 550px;
    padding: 20px;
    height: 350px;
    position: absolute;
    top: 200px;
    left: 30%;
    background-color: white;
    border-radius: 8px;
    border: 1px solid gray;
}
.InvteAdminCon input{
    width: 300px;
    height: 40px;
    border: 1px solid var(--main-color);
    margin: 4px;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 12px;
}
.InviteAdminLogoCon{
    width: 300px;
    height: 300x;
    position: absolute;
    top: -50px;
    left: 50px;
}
.InvitAdminH3{
    padding-bottom: 10px;
    border-bottom:1px solid ;
}
.AddTest{
    /* position: relative; */
    /* left: 17px; */
    /* top: 80px; */
    background-color: white;
    width: 100%;
    min-height: 375px;
}
.AddTest textarea{
    width: 280px;
    height: 100px;
}
.ExamDoneAlert{
    width: 100px;
    height: 50px;
    background-color: var(--main-color);
    position: absolute;
    top: -100px;
    left: 50%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: white;
    padding: 6px;
}
.ExamDoneAlertShow{
    top: 10px;
}
.ExamNotDoneAlert{
    width: 200px;
    height: 68px;
    background-color: red;
    position: absolute;
    top: 10px;
    left: 50%;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: white;
}
.TopEditExam{
    width: 100%;
    height: 195px;
    background: var(--BackGround-color-Blue);
    /* position: relative; */
    /* left: 222px; */
    /* top: 30px; */
}
.LoadingOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    z-index: 9999; /* Higher z-index to overlay everything */
}

.LoadingSpinner {
    display: inline-block;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border: 2px solid #333; /* Dark border */
    border-radius: 50%; /* Circular shape */
    border-top: 2px solid #fff; /* White top border for spinner effect */
    animation: spin 0.8s linear infinite; /* Rotate animation */
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.AddTest h3{
    position: relative;
    left: 43%;
    color: var(--main-color);
    width: 144px;
}
.AddTest h2 {
    font-size: 16px;
    color: black;
    font-family: inherit;
    margin: 8px;
}
.AddTest input {
    width: 180px;
    height: 25px;
    margin-top: 6px;
}

.AddTest .Flex{
    margin-top: 20px;
    display: flex;
}
.AddTest .Flex div{
}
.AddTest .Flex div span {
    font-size: 1em;
}
.AddTest .Flex div input {
    width: 100px;
}

form {
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  button {
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }

    .EditTest{
        width: 100%;
        height: 100%;
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .EditTest .EdittTestCon{
        width: 70%;
        height: 80%;
        background-color: white;
        padding: 10px;
        border-radius: 12px;
    }
    .EditTest .EdittTestCon  h3{

    }
    .EditTest .EdittTestCon form{
        border: 0;
    }
    .EditTest .EdittTestCon form span{
        display: block;
        font-weight: bolder;
        font-size: 16px;
    }
    .EditTest .EdittTestCon form input[type="number"],
    .EditTest .EdittTestCon form textarea,
    .EditTest .EdittTestCon form input[type="text"]{
        width: calc(100%);
        height: 55px;
        background-color: #f9f9f9d0;
        display: block;
        margin: 10px 0;
        border: 1px solid #ccc;
        border-radius: 20px;
        text-align: center;
    }
    .EditTest .EdittTestCon form .InpuCard{
        width: 48%;
        margin: 20px;
    }
    .EditTest .EdittTestCon form .DragAndDrob{
        display: flex;
        width: 100%;
        height: 135px;
        align-items: center;
        justify-content: center;
    }
    .EditTest .EdittTestCon form .DragAndDrob .DragFiles{
        width: 200px;
        height: 120px;
        position: relative;
        overflow: hidden;
        display: inline-block;
        border: 2px dashed #007bff;
        border-radius: 10px;
        background-color: #f8f9fa;
        cursor: pointer;
        text-align: center;
        margin: 15px;
        font-weight: 700;
        font-size: 13px;
        margin-top: 20px;
    }
    .EditTest .EdittTestCon form .DragAndDrob .DragFiles .DrahMSG{
        font-weight: 100;
    }
    .EditTest .EdittTestCon form .DragAndDrob .DragFiles:hover {
        border-color: #0056b3;
        background-color: #e2e6ea;
    }

    .EditTest .EdittTestCon form .DragAndDrob .DragFiles::after {
        color: #6c757d;
        font-size: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;
    }
    .EditTest .EdittTestCon form .DragAndDrob .DragFiles input[type="file"]{
        font-size: 100px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        opacity: 0;
        height: 100%;
    }
    .BtnAddTest{
        margin: 12px;
        width: 161px;
        height: 38px;
        }