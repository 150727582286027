/* 
/* Start Aside Bar */

.AsideBar{
    width: 100%;
    height: 100%;
}
.AsideBar .LogoContaner{ 
    width: 100%;
    height: 141px;
    text-align: center;
    position: relative;
    top: -19px;
    margin: auto;
}
.AsideBar .LogoContaner .DachpoardLogo{
    display: inline-block;
    width: 83%;
    height: 100%;
}
.AsideBar .QuickAccess{
    width: 100%;
    height: 61.7%;
    margin: -34px 0 10px 0;
}
.AsideBar .QuickAccess .H2{
    margin-left: 6px;
}
.AsideBar .QuickAccess .Sco{
    width: 100%;
    height: 90%;
}
.AsideBar .QuickAccess .Sco .AllDetils{
    width: 100%;
    height: calc(85% / 8);
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}
.AsideBar .QuickAccess .Sco .AllDetils .Detils{
    width: 198px;
    height: 49px;
    margin: auto;
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}
.AsideBar .QuickAccess .Sco .AllDetilsActive::before{
    content: '';
    width: 4px;
    height: 44px;
    background-color: #58B2D8;
    position: relative;
    left: 8px;
    top: 4px;
    border-radius: 0 10px 10px 0;
}
.AsideBar .QuickAccess .Sco .AllDetils .DetilsActive{
    background-color: #58B2D8;
    color: white;
}
.AsideBar .QuickAccess .Sco .AllDetils .Detils span:nth-child(1){
    margin-right: 25px;
    margin-left: 9px;
    color: white;
}
.AsideBar .QuickAccess .Sco .AllDetils .Detils span:nth-child(2){
    font-size: 16px;
    font-weight: 400;
}
.AsideBar .Account{
    width: 100%;
    height: 15%;
    margin: 10px 0 10px 0;
    position: relative;
    top: -21px;
}
.AsideBar .Account .H2{
    margin-left: 6px;
}
.AsideBar .Account .Sco{
    width: 100%;
    height: 60%;
}
.AsideBar .Account .Sco .AllDetils{
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
}
.AsideBar .Account .Sco .AllDetils .Detils{
    width: 198px;
    height: 35px;
    margin: auto;
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}
.AsideBar .Account .Sco .AllDetilsActive::before{
    content: '';
    width: 4px;
    height: 44px;
    background-color: #58B2D8;
    position: relative;
    left: 8px;
    top: 4px;
    border-radius: 0 10px 10px 0;
}
.AsideBar .Account .Sco .AllDetils .DetilsActive{
    background-color: #58B2D8;
    color: white;
}
.AsideBar .Account .Sco .AllDetils .Detils span:nth-child(1){
    margin-right: 25px;
    margin-left: 9px;
    color: white;
}
.AsideBar .Account .Sco .AllDetils .Detils span:nth-child(2){
    font-size: 16px;
    font-weight: 400;
}
.AsideBar .LogOut .AllDetils{
    width: 100%;
    height: 28px;
    display: flex;
    align-items: center;
}
.AsideBar .LogOut .AllDetils .Detils{
    width: 198px;
    margin: auto;
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}
.AsideBar .LogOut .AllDetils .Detils span:nth-child(1){
    margin-right: 25px;
    margin-left: 9px;
    color: white;
}
.AsideBar .LogOut .AllDetils .Detils span:nth-child(2){
    font-size: 16px;
    font-weight: 400;
}
.AsideBar .LogOut{
    width: 100%;
    height: 4.5%;
    margin: 10px 0 10px 0;
    position: relative;
    top: -18px;
}
/* End Aside Bar */
/* Start Top Line Bar */

.TopLine{
    position: relative;
    display: flex;
}
.TopLine .LeftSection{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 0;
}
.TopLine .LeftSection input{
    width: 413px;
    height: 38px;
    border: 0.6px solid #D5D5D5;
    border-radius: 38px;
}
.TopLine .LeftSection span{
    position: relative;
    left: 37px;
    top: -3px;
}
.TopLine .RightSection{
    width: auto;
    height: 100%;
    position: absolute;
    right: 40px;
}
.TopLine .RightSection .TopLineContaner {
    height: 100%;
    display: flex;
    align-items: center;
}
.TopLine .RightSection .TopLineContaner .Image{
    width: 35px;
    height: 35px;
    border-radius: 50%;
}
.TopLine .RightSection .TopLineContaner .Infor{
    width: auto;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
}
.TopLine .RightSection .TopLineContaner .Infor div:nth-child(1){
    width: auto;
    height: 34px;
    font-size: 18px;
}
.TopLine .RightSection .TopLineContaner .Infor div:nth-child(2){

}
.TopLine .RightSection .TopLineContaner .icon{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    transform: rotate(-90deg);
}
.TopLine .ToggleBar{
    font-size: 20px;
    margin-left: 15px;
    cursor: pointer;
    display: none;
}
.TopLine .CloseBar{
    font-size: 31px;
    margin-left: 15px;
    cursor: pointer;
    position: fixed;
    top: 0px;
    right: 12px;
    display: none;
}
/* End Top Line Bar */
/* Start Infro Boxes Bar */

.Infroboxes{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}
.Infroboxes .InfroBoxe{
    width: 20%;
    height: 100%;
    background-color: white;
    padding: 0 10px 0 10px;
    border-radius: 10px;
    display: flex;
}
.Infroboxes .InfroBoxe .Left{
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
}
.Infroboxes .InfroBoxe:nth-child(1) .Left div{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #FFD466;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.Infroboxes .InfroBoxe:nth-child(2) .Left div{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #D5EEF8;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.Infroboxes .InfroBoxe:nth-child(3) .Left div{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #FBB2C4;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.Infroboxes .InfroBoxe:nth-child(4) .Left div{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #C3EABD;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.Infroboxes .InfroBoxe .Right{
    width: 60%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Infroboxes .InfroBoxe .Right div:nth-child(1){
    display: block;
    height: 25px;
    /* line-height: 90px; */
}
.Infroboxes .InfroBoxe .Right div:nth-child(2){
    height: 48px;
    display: flex;
    align-items: end;
}

/* End Infro Boxes Bar */
/* Start Contnet Boxes Bar */

.ContentBoxes{
    border-radius: 5px;
}

/* End Content Boxes Bar */ 
/* Start Responsive */ 
@media (max-width: 750px) {
    .A{
        width: 100% !important;
        left: -500px;
        z-index: 5;
    }
}
@media (max-width: 800px) {
    .Individualda .InfroBoxes{
        display: none;
    }
    .Individualda .B{
        width: 90% !important;
    }
    .TopLine .LeftSection{
        display: none;
    }
    .TopLine .RightSection{
        right: 26px;
    }
    .TopLine .RightSection .TopLineContaner .Infor div{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .TopLine .RightSection .TopLineContaner .Image{
        width: 26px;
        height: 26px;    
    }
    .TopLine .RightSection .TopLineContaner .Infor div:nth-child(1){
        width: auto;
        /* height: 34px; */
        font-size: 15px;
    }
    .Individualda .B{
        height: 87% !important;
        grid-template-rows: 10% 80.8% !important;
        align-content: space-evenly !important;
        bottom: 0 !important;
    }
    .Individualda .TopLine{
        display: flex;
        align-items: center;
    }
    .TopLine .ToggleBar,
    .TopLine .CloseBar{
        display: block;
        z-index: 1000;
    }
    .AsideBar .QuickAccess .Sco .AllDetils .Detils,
    .AsideBar .Account .Sco .AllDetils .Detils,
    .AsideBar .LogOut .AllDetils .Detils{
        margin: 14px;
    }
    .AsideBar .LogoContaner .DachpoardLogo{
        width: 63%;
        height: 100%;
        margin: auto;
    }
}
/* End Responsive */ 