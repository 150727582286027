.ResetPasswrdForAdmin{
    background-image: url('../../Media/imges/backgroundLogin.jpg');
    background-size: cover; /* Cover the entire viewport */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the background image */
width: 100%;
height: 707px;
display: flex;
align-items: center;
justify-content: center;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin{
width: auto;
height: 440px;
display: flex;
flex-direction: column;
align-items: center;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResetPassLogoForAdmin{
display: flex;
align-items: center;
justify-content: center;
width: 250px;
height: 140px;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResetPassLogoForAdmin img {
    /* width: 186px; */
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin{
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResetPassTextForAdmin{
    width: 100%;
    margin-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin .H1tex{
    font-weight: 300;
    font-size: 32px;
    letter-spacing: 6px;
    color: white;
}

.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin .SupmitFormForAdmin{
    width: 100%;
    height: 174px;
    margin: 0;
    padding: 0;
    border: 0;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin .InptCont{
    margin: 20px 0 45px 0;
    width: 100%;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin .InptCont .ImgAndTilte{
    display: flex;
    position: relative;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin .InptCont  .ImgeInput{
    display: inline;
    z-index: 1;
    margin-top: -2px;
    color: white;
}

.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin .InptCont .ImgAndTilte .title{
    z-index: 1;
    margin-top: -7px;
    margin-left: 16px;
    color: white;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin .InptCont .RegInput{
    position: absolute;
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: 1px solid white;
    text-indent: 33%;
    font-size: 18px;
    background-clip: text;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResePasstBoxForAdmin .InptCont .RegInput:focus{
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid white;
    outline: none;
}

.ResetPasswrdForAdmin .ResetPssContanerForAdmin .Contaner{
    width: 40%;
}
.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResetPassBtnsForAdmin .ResetBTN{
    background: transparent;
    font-style: oblique;
    border: 0;
}

.ResetPasswrdForAdmin .ResetPssContanerForAdmin .ResetPassBtnsForAdmin  .ToSend{
    width: 100%;
    height: 45px;
    font-size: 18px;
    letter-spacing: 3px;
}